@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
  overflow-x: hidden !important;
  top: 0px !important;
  max-height: 100vh;
}
.page-active{
border-bottom: 2px solid;
}
.pagination-class{
  display: flex;
  list-style: none;
  gap:40px;
  font-size: 1.3rem;
  font-weight: 600;
  color:#005f3b;
  margin: auto;
  width: 100%;
justify-content: center;
}
.pagination-class > li{
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #007bff;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  padding: 20px;
  overflow-y: auto;
  z-index: 1000; /* Ensure it overlays other elements */
}

.sidebar.open {
  transform: translateX(0);
}

/* Toggle button styles */
.toggle-button {
  position: fixed;
  top: 20px;
  left: 20px; /* Adjust this if you want it closer to the sidebar */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1001; /* Higher than sidebar for visibility */
}

/* Sidebar content styles */
.sidebar-content {
  margin-top: 50px;
}

.option {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
a {
  text-decoration: none;
  all: unset;
}
a{
  /* background-color: red !important; */
}
.option p {
  background-color: white;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.reset-button {
  margin-top: 20px;
  background-color: #0062cc;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}
.mw800{
  max-width: 800px;
}
.fb50{
  flex-basis:50%
}
.mw1000{
  max-width: 1000px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 99vw;
}
#navbarMain {
  z-index: 99;
}
.skiptranslate {
  visibility: hidden !important;
  opacity: 0 !important;
  /* display: none;
  visibility: hidden; */
  /* height: 0 !important; */
}
.paginationContainer {
  display: flex;
  align-items: center;
}
.goog-te-gadget {
  height: auto !important;
  display: block !important;
  visibility: visible !important;
}
#google_translate_element {
  display: block !important;
  visibility: visible !important;
}
.paginationClass {
  list-style: none;
  display: flex;
  margin: auto;
  padding-inline-start: 0px;
}
.paginationClass > li {
  /* border: 1px solid green; */

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.paginationClass > ul {
}
.o6 {
  opacity: 0.6;
}
#navbarTop {
  transition: height 0.3s ease-in-out;
  animation: height 0.3s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
}
.mengapaPoinWrapper {
  background-color: #005f3b;
  max-width: 70%;
  border-radius: 0px 10px 10px 0px;
}
.mengapaPoin {
  border-radius: 20px;
  background-color: white;
  /* border: 1px solid #005F3B; */

  text-align: justify;
}
#navbarBottom {
  transition: padding-top 0.2s ease-in-out;
  z-index: 1;
}
.swiper-landing-slide {
  height: 45rem !important;
}
#newsImg {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}
.swal2-popup {
  font-size: 0.8rem !important;
}
.swal2-title {
  font-family: "Roboto" !important;
}
.swal2-html-container {
  font-family: "Roboto" !important;
}
.newsImgContainer {
  /* opacity: 0.6; */
  transition: opacity 0.9s;
}
.navbarContainer {
  height: 5.8rem;
  z-index: 99;
  position: relative;
  /* background-color:wheat ; */
  /* width: 100%; */
}
.bottomNavbar {
  padding-bottom: 0.4rem;
}
.gmap-component {
  width: 2001px !important;
}
.subMenuWrapper {
  margin-top: 6rem !important;
}
.landingContainer {
  /* height: 50rem; */
  /* width: 100% !important; */
  /* background-color: beige; */
  margin-top: 8rem;
  transition: margin-top 0.3s;
}
.katalogByIdDesc {
  min-width: 500px;
}
.mobile {
  display: none;
}
.grid50 {
  display: grid;
  grid-template-columns: 50% 50%;
  /* background-color: ; */
}
.center-vertical {
  vertical-align: middle !important;
  display: flex !important;
  align-items: center !important;
}
.mobileNavbar {
  display: none !important;
  padding-bottom: 0.4rem;
}
.homePageWrapper {
  background-color: #f5f5f5;
}
.swiperPenghargaan > .swiper-wrapper {
  /* vertical-align: middle !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  justify-content: center; */
}

.swiperPenghargaan > .swiper-wrapper > .swiper-slide {
  /* margin: auto;
  width: 100%; */
}
.swiperPenghargaan {
  text-align: center;
}
.upperHomeContainer {
  width: 90.27%;
}
.mw90 {
  max-width: 90%;
}
.mw1000 {
  max-width: 1000px;
}
.mw100 {
  max-width: 100%;
}
.mw45 {
  max-width: 45%;
}
.mw35 {
  max-width: 35%;
}
.mw20 {
  max-width: 20%;
}
/* .wb-input:focus{
  border-color: rgba(126, 239, 104, 0.8) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6) !important;
  outline: 0 none;
} */
.mw800 {
  max-width: 800px;
}
.mw600 {
  max-width: 600px;
}
.mw500 {
  max-width: 500px;
}
.w100 {
  width: 100%;
}
.w25 {
  width: 25%;
}
.flex {
  display: flex;
}
.greenText {
  color: #005f3b;
}
.fw400 {
  font-weight: 400;
}
.text-right {
  text-align: right;
}
.hoverDark:hover {
  filter: brightness(90%);
}
.penghargaanWrapper {
  width: 82.583%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 100%;
  /* display: block; */
}
.navbarFont {
  font-size: 18px;
}
.footerContainer {
  height: 45px;
  background-color: #005f3b;
}
.mw28 {
  max-width: 28%;
}
.badge-quaternary {
  background-color: #383f48
}
.badge-secondary {
  background-color: #005f3b
}
.badgex {

border-radius: 4px;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.tab1 {
  padding-left: 3rem;
}
.tabMenu {
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
.mengapaImgWrapper {
  margin-left: 3rem;
}
.tabUnderline {
  height: 3px;
  width: 0%;
  transition: width 200ms;
}
.tabName {
  transition: background 200ms;
}
.tabMenu:hover .tabUnderline {
  width: 100%;
  background-color: #005f3b;
}
.tabMenu:hover .tabName {
  background-color: #8bad97;
}
.tabActive .tabName {
  background-color: #8bad97 !important;
}
.tabActive .tabUnderline {
  width: 100%;
  background-color: #005f3b;
}
.footerWrapper {
  gap: 40px;
}
.br10 {
  border-radius: 10px !important;
}
.h100 {
  height: 100%;
}
.mh100 {
  max-height: 100%;
}
.bottomMenuContainer {
  width: 69.5%;
  margin-bottom: 110px;
}
.layananImg {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: 0.4s;
  height: 27.5rem;
}
.swiperNav {
  width: 20px;
}
.nowrap {
  white-space: nowrap;
}
.bottomMenuWrapper {
  gap: 150px;
}
.swiper-pagination-bullet-active {
  background-color: #005f3b !important;
}
.layananImg:hover {
  -webkit-filter: none;
  filter: none;
  /* transform: scale(1.1);  */
  cursor: pointer;
}
.swiperStock > .swiper-wrapper {
}
.swiperLayanan > .swiper-pagination {
}
.swiperLanding > .swiper-pagination {
  bottom: 195% !important;
}
.swiperTenant > .swiper-wrapper {
  justify-content: space-between;
}
.slideTenant {
  text-align: center;
}
.swiperStock {
  border-radius: 10px;
}
.searchNav {
  max-width: 0px;
  border: none;
  transition: 0.63s;
  background-color: #f5f5f5;
}
.searchNav:focus ~ .searchHover {
  background-color: white !important;
}
.white {
  color: white;
}
.searchNav:focus {
  max-width: 200px;
  outline: none;
  background-color: white;
}
.hover-underline {
  border: 2px solid transparent;
}
.hover-underline:hover {
  border-bottom: 2px solid rgb(0, 95, 59);
  text-shadow: 0px 0px 1px black;
}
.hover-text-shadow:hover {
  text-shadow: 0px 0px 1px black;
}
.searchHover:has(:focus-visible) {
  background-color: white;
  border: 1px solid rgb(0, 95, 59);
}
.searchHover {
  padding: 1rem;
  transition: 0.63s;
  border-radius: 20px;
}
.akhlakImg {
  transition: transform 0.3s;
}
.mapWrapper {
  height: 800px;
}
.katalogWrapper {
  /* display: grid;
  grid-template-columns: 50% 50%; */
}
.akhlakImg:hover {
  transform: scale(1.1);
}
.zoom12 {
  transition: transform 0.3s;
}
.zoom12:hover {
  transform: scale(1.2);
}
.searchHover:hover {
  border: 1px solid rgb(0, 95, 59);
  background-color: white;
}
.searchHover:has(.focus) {
  background-color: white !important;
}
.searchHover:hover .searchNav {
  transition: 0.63s;
  max-width: 300px;
  background-color: white;
}
.kbnDescBottom {
  /* width: 59%; */
}
.floatingChat {
  transition: transform 0.2s;
}
.floatingChat:hover {
  transform: scale(1.3);
}
.kbnLogoBottomDiv {
  /* width: 27%; */
}
.kbnDesc {
  /* width: 69%; */
}
.tenantContainer {
  /* width: 55%; */
}
.hoverBold {
}
.hoverBold:hover {
  text-shadow: 0px 0px 1px black;
}
.beritaWrapper::-webkit-scrollbar {
  width: 8px;
}
.text-left {
  text-align: left;
}
.stockDetails {
  max-height: 133px;
}
.contactFooter {
  filter: brightness(0) invert(1);
  filter: brightness(0) saturate(100%) invert(27%) sepia(26%) saturate(1887%)
    hue-rotate(113deg) brightness(97%) contrast(102%);
}
.beritaWrapper::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);  */
  border-radius: 5px;
  background-color: #599972;
}
.sosmedimg {
  filter: brightness(0) invert(1);
}
.beritaWrapper::-webkit-scrollbar-thumb {
  border-radius: 5px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(85, 13, 13, 0.3);  */
  background-color: #a3ebc0;
}
.bottomMenuLink {
  gap: 50;
}
.dropdownMenu {
  position: relative;
}
.borderP {
  height: 5rem;
}
.dropdownDialog {
  display: block;
  position: absolute;
  background-color: #f5f5f5;
  box-shadow: 0 28px 16px rgba(0, 3, 0, 0.2);
  z-index: 99;
  visibility: 0;
  opacity: 0;
  top: 100%;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: rgb(0, 95, 59);
  font-size: 18px;
  font-family: Roboto;
  font-weight: 300;
  overflow-wrap: break-word;
  pointer-events: none;
}
.katalogMapSelect:focus {
  border-color: rgb(0, 95, 59) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #65a765 !important;
}
.katalogMapSelect {
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: rgb(0, 95, 59);
  font-size: 18px;
  font-family: Roboto;
  font-weight: 300;
  overflow-wrap: break-word;
  gap: 20px;
  width: 80%;
  z-index: 3;
  position: relative;
  margin-top: 100px;
}
.table > :not(:first-child) {
  border-top: 1px solid white;
}
.listName {
  font-size: 32px !important;
  color: #005f3b !important;
  font-weight: 600 !important;
  line-height: 1;
}
.listPrice {
  font-size: 24px !important;
  color: #005f3b !important;
  font-weight: 500 !important;
}
.w50 {
  width: 50%;
}
.katalogItem {
  height: 15rem;
  background-color: white;
}
#katalogListId {
  /* background-color: red; */
  transition: max-width 600ms ease-in-out;
  white-space: nowrap;

  opacity: 0;
  width: 0;
  max-width: 0%;
}
.o0 {
  opacity: 0;
  transition: opacity 0.13s;
}
.o1 {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
  transition: opacity 0.5s;
  opacity: 1 !important;
}
.katalogList {
  max-width: 100% !important;
  width: 100% !important;
  opacity: 1 !important;
  display: block !important;
}
.fotoCardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.fotoCard {
  height: 27rem;
  width: 300px;

  max-width: 18%;
  border-radius: 20px;
}
.popupFotoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.newsCard {
  width: 500px;

  max-width: 22%;
  border-radius: 20px;
}
.albumThumbnail {
  width: 100%;
  height: 70%;
  border-radius: 20px 20px 0px 0px;
  /* filter: drop-shadow(5px 5px 10px #9e8f8f); */
}
.cardName {
  width: 100%;
  height: 30%;
  background-color: white;
  text-align: center;
  justify-content: center;
  font-weight: 700 !important;
  color: #005f3b !important;
  border-radius: 0px 0px 20px 20px;
}
.newsName {
  width: 100%;

  text-overflow: ellipsis;
  max-height: 8rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px !important;

  font-weight: 900 !important;
  color: #005f3b !important;
}
.newsIntro {
  width: 100%;

  text-overflow: ellipsis;
  max-height: 5rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px !important;
}
.yt {
  height: 100%;
  border-radius: 20px;
}
.react-pdf-page {
  width: 100% !important;
  height: 100% !important;
}
.newsThumbnail {
  width: 100%;
  height: 350px;
  border-radius: 20px;
  margin-bottom: 2.27rem;
}
.react-pdf__Page {
  --scale-factor: 0.2 !important;
  background-color: transparent !important;
  position: relative;
  min-width: auto !important;
  min-height: auto !important;
  width: 100%;
  height: 100% !important;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
  max-height: 100% !important;
}
.galleryImg {
  min-width: 250px;
  max-width: 10%;
}
.galleryModal {
  max-width: 60%;
  margin-bottom: 0px !important;
  background-color: #f5f5f5;
}
.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}
.f24 {
  font-size: 24px;
}
.f36 {
  font-size: 36px;
}
.f48 {
  font-size: 48px;
}
.modalTitle {
  font-size: 27px;
}
.dropdownDialog:hover {
}
.form-control:focus {
  border-color: #b5e8a4;
  box-shadow: 0 0 0 0.2rem #d8f3dc;
}

.form-control {
  font-family: Roboto !important;
}
.hoverNoColor:hover {
  color: black !important;
}
.dropdownMenu:hover .dropdownDialog {
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: all;
}
.dropdownSubmenu {
  white-space: nowrap;
  padding-left: 1.2rem;
  padding-right: 4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid black;
}
.dropdownSubmenu:hover {
  background-color: #dddddd;
}
#google_translate_element {
  /* display: none; */
  z-index: 199;
  position: absolute;
}
.chatWrapper {
  width: 300px;
}
.newsTitle {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
.berita {
  height: 600px;
}
.newsTitle:hover {
  text-decoration: underline;
}
.flex-wrap {
  flex-wrap: wrap;
}
.kbnNavbarMobile {
  width: 14%;
}
.navbarRightDiv {
  width: 20%;
}
.mw80 {
  max-width: 80%;
}
.mw500 {
  max-width: 500px;
}
.mw90vw {
  max-width: 90vw;
}
.mw400 {
  max-width: 400px;
}
.mw300 {
  max-width: 300px;
}
.Xcontainer {
  background-size: cover;
  background-position: center;
  height: 100vh; /* You can set an initial height if needed */
  display: flex;
  align-items: center;
  justify-content: center;
}
.brMobile {
  display: none;
}
.pageMainImg {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding-top: 3rem;
  margin-bottom: 3rem;
}
.subTitle {
  font-size: 16px;
}
.articleContainer {
  padding: 20px;
}

.magazineGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.magazineItem {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.albumThumbnail {
  height: 300px; /* Adjust as needed */
  background-position: center;
  background-size: cover;
  position: relative;
}

.magazineItemTitle {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 10px 10px;
}

.galleryImg {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.btn {
  border-radius: 50rem;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  /* border: 1px solid #005f3b; */
  background: #005f3b;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s;
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  color: auto !important;
}

.galleryModal {
  background: rgba(255, 255, 255, 0.95) !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.pageTitle {
  color: #005f3b;
  font-size: 48px;
  font-family: Roboto;
  font-weight: 700;
  text-transform: capitalize;

  word-wrap: break-word;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.katalogByIdDescTable {
  border: 0px solid transparent;
  line-height: 1.5;
  border-spacing: 0;
  width: auto !important;
  display: flex !important;
}
.katalogByIdDescTable td {
  padding: 0 !important;
}
.katalogByIdDescTable ul {
  margin-block-end: 0;
}
.pageText {
  color: #1e1e1e;
  font-size: 22px;
  font-family: Roboto;
  font-weight: 400;
}
.bold {
  font-weight: bold;
}
.articleContainer {
  color: #1e1e1e;
  font-size: 22;
  font-family: Roboto;
  font-weight: 400;
  min-height: 30vh;
  word-wrap: break-word;
  text-align: justify;
}
.burgerMenu {
  width: 34px;
  margin-top: 2rem;
}
.swiper {
  z-index: 0 !important;
}
.table {
  display: table;
}
.menuMobi .swiper-wrapper {
  z-index: 0 !important;
}
.mobileSubmenuWrapper {
  max-height: 0px;
  visibility: hidden;
  transition: max-height 0.6s, opacity 0.2s;
  opacity: 0;
}
.mobileSubmenuWrapperActive {
  max-height: 99vh !important;
  visibility: visible;
  opacity: 1;
}
#mobileMenu {
  /* max-height: 0px ; */
  display: none;
  visibility: hidden;
  transition: max-height 0.8s, opacity 0.3s ease-in-out;
  opacity: 0;
}
.mobileMenuActive {
  display: block !important;
  /* max-height: 100vh !important; */
  visibility: visible !important;
  opacity: 1 !important;
  transition: max-height 0.8s, opacity 0.3s ease-in-out;
}
.text-none {
  text-decoration: none;
  color: currentColor !important;
}
.mw400 {
  max-width: 400px;
}
.text-none:hover {
  color: currentColor !important;
}

@media only screen and (max-width: 1600px) {
  .borderP {
    height: 4rem;
  }
}

@media only screen and (max-width: 1500px) {
  .mengapaPoinWrapper {
    max-width: 55%;
  }
  .borderP {
    height: 3rem;
  }
  .newsThumbnail {
    height: 250px;
  }.fotoCard {
    height: 27rem;
    width: 300px;
  
    max-width: 25%;
    border-radius: 20px;
  }
  .pageText {
    font-size: 17px !important;
  }
  .layananMenu {
    font-size: 18px !important;
  }
  .upFont {
    font-size: 13px !important;
  }
  .homeSubheader {
    font-size: 38px !important;
  }
  .layananImg {
    height: 23rem;
  }
  .ptitle {
    font-size: 16px !important;
  }
  .newsTitle {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .mengapaImgWrapper {
    background-position: left;
  }
  .kbumn {
    height: 30px !important;
  }
  .dki {
    height: 25px !important;
  }
  .danareksa {
    height: 22px !important;
  }
  .layananImg {
    height: 20rem;
  }
  .bottomMenuWrapper {
    gap: 80px;
  }
  .searchHover:hover .searchNav {
    font-size: 15px;
    transition: 0.63s;
    max-width: 100px;
    background-color: white;
  }
  .searchNav:focus {
    max-width: 100px;
    outline: none;
    background-color: white;
  }
  .dropdownMenu {
    font-size: 14px;
  }
  .navbarFont {
    font-size: 15px;
  }
  .bottomMenuContainer {
    width: 98%;
  }
  .beritaTitle {
    margin-left: 10rem;
  }
  .berita {
    height: 450px !important;
  }
  .floatingChat {
    margin-left: 90% !important;
  }
  .katalogByIdDesc {
    min-width: 400px;
  }
}
@media only screen and (max-width: 1300px) {
  .mengapaPoinWrapper {
    max-width: 52%;
  }
  .upperHomeContainer {
    width: 91.5%;
  }
  .kbnAdalah {
    width: auto !important;
  }
  .tenantContainer {
    width: 80%;
  }
  .swiper-landing-slide {
    height: 35rem !important;
  }
  .newsImgContainer {
    width: 35% !important;
    height: 380px !important;
  }

  .n1300 {
    display: none !important;
  }
  .b1300 {
    display: block !important;
  }
  .imbg {
    width: 94vw !important;
    height: 97%;
  }

  .w1300100 {
    width: 100% !important;
  }
  
  .mw28 {
    max-width: 15%;
  }
}
@media only screen and (max-width: 1200px) {
  .fwrap1200 {
    flex-wrap: wrap;
  }
  .greenText.mx-3.pt-3.fw400 {
    font-size: 18px !important;
    white-space: nowrap;
  }
  .layananImg {
    height: 18rem;
  }
  .block1200 {
    display: block;
  }
  .mw4001200 {
    max-width: 400px;
  }
  .greenText.mx-3.pt-1.pb-1 {
    font-size: 28px !important;
    white-space: nowrap;
  }
  .m-font {
    font-size: 12px !important;
  }
  .penghargaanWrapper {
    width: 80%;
  }
  .homeText {
    width: auto !important;
  }
  .none1200 {
    display: none;
  }
  .fe1200 {
    justify-content: flex-end !important;
  }
  .navbarFont {
    font-size: 12px;
  }
 
  .mt31300{
    margin-top: 3rem !important;
  }
}
@media only screen and (max-width: 1100px) {
  .mengapaImgWrapper {
    display: none;
  }
  .mengapaPoinWrapper {
    max-width: 100%;
  }
  .newsCard {
    max-width: 46%;
  }
}
@media only screen and (max-width: 1000px) {
  .fotoCard {
    height: 27rem;
    width: auto;
  
    max-width: 100%;
    border-radius: 20px;
  }
  .sertipeng {
    display: block;
  }
  .mt31000{
    margin-top: 3rem;
  }
  .w1000100 {
    width: 100% !important;
  }
  .none1000{
    display: none !important;
  }
  .block1000 {
    display: block !important;
  }
  .katalogItem {
    background-color: #f5f5f5;
  }
  .pageTitle {
    font-size: 32px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  .subMenuWrapper {
    margin-top: 0.78rem !important;
  }
  .newsCard {
    max-width: 46%;
  }
  .kbnDescBottom {
    width: fit-content !important
    ;
  }
  .katalogWrapper {
  }
  .landingContainer {
    margin-top: 2rem !important;
  }
  .pageMainImg {
    height: 200px;
  }
  #navbarMain {
    background-color: white !important;
  }
  .homePageWrapper {
    background-color: white;
  }
  .swiperNav {
    width: 10px;
  }
  .mobileNavbar {
    display: flex !important;
    visibility: visible;
  }
  .kbnDescBottom {
    display: block !important;
    width: 80%;
    margin: auto;
  }
  .bottomMenuLink {
    width: 80%;
    margin: auto;
    margin-top: 3rem;
  }
  .topMenu {
    width: 100%;
    gap: inherit !important;
    justify-content: space-between !important;
  }
  .bottomNavbar {
    display: none !important;
  }
  .phoneNavbar {
    display: none !important;
  }
  .bottomMenuWrapper {
    display: block !important;
  }
  .kbnLogoBottom {
    width: 60%;
  }
  .kbnLogoBottomDiv {
    width: 60%;
    margin: auto;
    text-align: center;
    margin-bottom: 3rem;
  }
  .kbnDesc {
    width: 100%;
    text-align: justify;
  }
}
@media only screen and (max-width: 800px) {
  .px0800{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .newsCard {
    max-width: 90%;
  }
  .akhlakImg {
    max-width: 80vw !important;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
  }
  .m-w70 {
    width: 70% !important;
  }
  .floatingChat {
    margin-left: 84% !important;
  }
  .footerContainer {
    height: auto;
    align-items: center;
  }
  .mobile-none {
    display: none;
  }
  .tenantContainer {
    width: 70%;
  }
  .swiper-landing-slide {
    height: 25rem !important;
  }
  .my-5 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 1.5rem !important;
  }
  .mt-5 {
    margin-top: 1.5rem !important;
  }
  .newsImgContainer {
    width: 65% !important;
    height: 180px !important;
  }
  .kbnDescBottom {
    width: 100%;
    text-align: justify;
  }
  .pb-5 {
    padding-bottom: 1.5rem !important;
  }
  .py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 1.5rem !important;
  }
  .m-block {
    display: block !important;
  }
  .m-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .m13 {
    font-size: 13px !important;
  }
  .m12 {
    font-size: 12px !important;
  }
  .m20 {
    font-size: 20px !important;
  }
  .m27 {
    font-size: 27px !important;
  }
  .m11 {
    font-size: 11px !important;
  }
  .mw70 {
    width: 70% !important;
  }
  .brMobile {
    display: block;
  }
  .kbnNavbarMobile {
    width: 22%;
  }
  .footerWrapper {
    gap: 10px;
  }
  .m-none {
    display: none;
  }
  .mobile {
    display: block;
  }
  .mw50 {
    width: 50%;
  }
  .berita {
    height: 600px !important;
    width: 82% !important;
  }
  .beritaList {
    padding-top: 8rem;
  }
  .slideTenant {
  }
  .beritaTitle {
  }
  .m-o0 {
    opacity: 0;
  }
  .m11 {
    font-size: 11px !important;
  }
  .beritaListWrapper {
    width: 78% !important;
    margin-left: 10% !important;
  }
  .sosmedFooter {
    justify-content: center;
    padding: 0.7rem;
  }
  .footerWrapper {
    width: fit-content !important;
  }
  .bottomMenuContainer {
    margin-bottom: 50px;
  }
  .copyright {
    padding-bottom: 0.7rem;
  }
}
@media only screen and (max-width: 600px) {
  .beritaList {
    padding-top: 4rem;
  }
  .navbarContainer {
    height: 4rem;
  }
}
@media only screen and (max-width: 500px) {
  .beritaList {
    padding-top: 1rem;
  }
}
